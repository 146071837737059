<template>
  <!-- 沟通进度弹窗 -->
  <el-dialog
    title="沟通进度"
    :visible.sync="dialogVisible"
    width="800px"
    append-to-body
  >
    <div id="comContainer">
      <div class="comList" ref="comList">
        <el-divider v-if="communicationList.length < total"
          ><span @click="loadHistory" class="loadMore"
            >显示更多历史记录</span
          ></el-divider
        >
        <div
          style="margin-bottom: 12px"
          v-for="(comText, index) in communicationList"
          :key="index"
        >
          <div v-if="!comText.self" class="him">
            <div class="userName">
              {{ comText.name }} {{ comText.createTime }}
            </div>
            <div>
              <img :src="comText.avatar" alt="" class="avatar" />
              <div class="content">{{ comText.content }}</div>
            </div>
          </div>
          <div v-else class="me">
            <div class="userName">
              {{ comText.createTime }} {{ comText.name }}
            </div>
            <div>
              <div class="content">{{ comText.content }}</div>
              <img :src="comText.avatar" alt="" class="avatar" />
            </div>
          </div>
        </div>
      </div>
      <div class="replyArea">
        <el-input
          v-model="reply"
          class="replyText"
          type="textarea"
          resize="none"
          :autosize="{ minRows: 4, maxRows: 4 }"
          @keydown.enter.native="enterEvent($event)"
        >
        </el-input>
        <div style="text-align: right">
          <el-button type="primary" size="mini" @click="sendReply"
            >发送</el-button
          >
        </div>
      </div>
    </div>
  </el-dialog>
</template>
<script>
import { getComList, sendReply } from "@/service/interpolation";
import { getWebSocketURL } from "@/service/index";
import { mapState } from "vuex";
export default {
  name: "CommunicationDialog",
  props: {
    currentId: {
      type: [Number, String],
      default: "",
    },
    visible: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      communicationList: [],
      currentPage: 1,
      reply: "",
      total: 0,

      socketPath: "",
      socket: "",
      lockReconnect: false, //是否真正建立连接
      timeout: 500,
      timeoutObj: null, //心跳心跳倒计时
      serverTimeoutObj: null, //心跳倒计时
      timeoutnum: null, //断开 重连倒计时
    };
  },
  methods: {
    sendReply() {
      if (this.reply == "") {
        return this.$message.error("发送内容不能为空！");
      }
      sendReply({
        id: this.currentId,
        content: this.reply,
      }).then((res) => {
        if (res.success) {
          this.reply = "";
        } else {
          this.$message.error(res.message);
        }
      });
    },
    enterEvent(event) {
      let e = window.event || arguments[0];
      if (
        !e.ctrlKey &&
        (e.key == "Enter" || e.code == "Enter" || e.keyCode == 13)
      ) {
        e.returnValue = false;
        if (this.reply == "") {
          return this.$message.error("发送内容不能为空！");
        }
        sendReply({
          id: this.currentId,
          content: this.reply,
        }).then((res) => {
          if (res.success) {
            this.reply = "";
          } else {
            this.$message.error(res.message);
          }
        });
        return false;
      } else if (
        e.ctrlKey &&
        (e.key == "Enter" || e.code == "Enter" || e.keyCode == 13)
      ) {
        console.log("???");
      }
    },
    loadHistory() {
      getComList(this.currentId, {
        first: true,
        zone: new Date().getTimezoneOffset() / 60,
        current: this.currentPage + 1,
      }).then((res) => {
        if (res.success) {
          if (res.result.records.length > 0) {
            this.currentPage = this.currentPage + 1;
          }

          this.communicationList = res.result.records.concat(
            this.communicationList
          );
          this.total = res.result.total;
        }
      });
    },

    socketInit() {
      if (typeof WebSocket === "undefined") {
        alert("您的浏览器不支持socket");
      } else {
        // 实例化socket
        // console.log(this.socketPath)
        this.socket = new WebSocket(this.socketPath);
        // 监听socket连接
        this.socket.onopen = this.socketOpen;
        // 监听socket错误信息
        // this.socket.onerror = this.error
        // 监听socket消息
        this.socket.onmessage = this.getMessage;
      }
    },
    socketOpen() {
      this.start();
      this.socket.send(
        JSON.stringify({
          method: 4,
          message: {
            userId: this.userInfo.id,
            id: this.currentId,
            zone: new Date().getTimezoneOffset() / 60,
          },
        })
      );
    },
    start() {
      this.timeoutObj && clearTimeout(self.timeoutObj);
      this.serverTimeoutObj && clearTimeout(self.serverTimeoutObj);
      this.timeoutObj = setTimeout(() => {
        //这里发送一个心跳，后端收到后，返回一个心跳消息，
        if (this.socket.readyState == 1) {
          //如果连接正常
          this.socket.send(
            JSON.stringify({
              method: 4,
              message: {
                userId: this.userInfo.id,
                id: this.currentId,
                zone: new Date().getTimezoneOffset() / 60,
              },
            })
          );
        } else {
          //否则重连
          this.reconnect();
        }
        this.serverTimeoutObj = setTimeout(() => {
          //超时关闭
          this.socket.close();
        }, this.timeout);
      }, this.timeout);
    },
    reconnect() {
      //重新连接
      var that = this;
      if (that.lockReconnect) {
        return;
      }
      that.lockReconnect = true;
      //没连接上会一直重连，设置延迟避免请求过多
      that.timeoutnum && clearTimeout(that.timeoutnum);
      that.timeoutnum = setTimeout(function () {
        //新连接
        that.socketInit();
        that.lockReconnect = false;
      }, 1000);
    },
    getMessage(msg) {
      this.reset();
      console.log(msg.data);
      if (msg.data && JSON.parse(msg.data).resultMessage) {
        this.communicationList.push(JSON.parse(msg.data).resultMessage);
        this.$nextTick(() => {
          this.$refs.comList.scrollTop = this.$refs.comList.scrollHeight;
        });
      }
    },
    reset() {
      //重置心跳
      //清除时间
      clearTimeout(this.timeoutObj);
      clearTimeout(this.serverTimeoutObj);
      //重启心跳
      this.start();
    },
  },
  watch: {
    dialogVisible(val) {
      if (val) {
        getComList(this.currentId, {
          first: true,
          zone: new Date().getTimezoneOffset() / 60,
        }).then((res) => {
          if (res.success) {
            this.communicationList = res.result.records;
            this.total = res.result.total;
            this.$nextTick(() => {
              this.$refs.comList.scrollTop = this.$refs.comList.scrollHeight;
            });
          }
        });
        getWebSocketURL().then((res) => {
          this.socketPath = res.result;
          this.socketInit();
        });
      } else {
        this.currentPage = 1;
        this.communicationList = [];
        this.total = 0;
        this.socket.close();
      }
    },
    $route() {
      if (this.socket) {
        this.socket.close();
      }
    },
  },
  computed: {
    dialogVisible: {
      get() {
        return this.visible;
      },
      set(val) {
        this.$emit("changeDialogVisible", val);
      },
    },
    ...mapState(["userInfo"]),
  },
  beforeDestroy() {
    if (this.socket) {
      this.socket.close();
    }
  },
};
</script>
<style lang="scss" scoped>
#comContainer {
  width: 680px;
  min-height: 480px;
  background: rgba(136, 148, 171, 0.1);
  border-radius: 8px;
  border: 1px solid #eaeaea;
  margin: 0 auto;
  position: relative;
  .replyArea {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    background: rgba(136, 148, 171, 0);
    border-radius: 0px 0px 8px 8px;

    border-top: 1px solid #eaeaea;
    padding: 8px 16px;
    .replyText {
      width: 100%;
      margin-bottom: 12px;
      resize: none;
    }
  }
  .comList {
    height: 325px;
    width: 100%;
    padding: 12px 45px;
    overflow-y: auto;
  }
}
::v-deep .el-dialog {
  border-radius: 8px;
}
::v-deep .el-dialog__body {
  padding-bottom: 40px;
  padding-top: 10px;
}
.userName {
  font-size: 12px;
  font-family: SourceHanSansCN-Medium, SourceHanSansCN;
  font-weight: 500;
  color: #828282;
  line-height: 18px;
  margin-bottom: 8px;
}
.him {
  .content {
    background: #fff;
    margin-left: 6px;
  }
  .userName {
    margin-left: 36px;
  }
}
.me {
  text-align: right;
  .content {
    background: rgba(0, 117, 246, 0.1);
    margin-right: 6px;
  }
  .userName {
    margin-right: 36px;
  }
}
.content {
  border-radius: 4px;
  padding: 8px 12px;
  display: inline-block;

  font-size: 12px;
  font-family: SourceHanSansCN-Medium, SourceHanSansCN;
  font-weight: 500;
  color: #828282;
  line-height: 14px;
}
.avatar {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  vertical-align: top;
}
.loadMore {
  cursor: pointer;
}
::v-deep .el-divider__text.is-center {
  background: #f3f4f6;
}
</style>