<template>
  <!-- 内推列表 -->
  <div>
    <el-table :data="tableData">
      <el-table-column label="公司名称" width="210">
        <template slot-scope="scope">
          <img :src="scope.row.logo" alt="" class="companyLogo" />
          <span class="companyName">
            {{ scope.row.company }}
          </span>
        </template>
      </el-table-column>
      <el-table-column label="岗位类别" prop="post.name"></el-table-column>
      <el-table-column label="岗位ID链接" prop="postId"></el-table-column>
      <el-table-column label="申请时间" prop="createTime"></el-table-column>
      <el-table-column label="内推状态" width="150">
        <template slot-scope="scope">
          {{ scope.row.status && scope.row.status.name }}
          <el-button
            type="text"
            v-if="scope.row.status && scope.row.status.value == 2"
            @click.stop="openCommunicationDialog(scope.row)"
            >查看进度</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <div style="text-align: center; padding: 20px">
      <el-pagination
        v-if="total > 10"
        :current-page.sync="currentPage"
        :total="total"
        layout="prev, pager, next"
        @current-change="getAccessList"
      >
      </el-pagination>
    </div>
    <CommunicationDialog
      :visible="communicationDialogVisible"
      @changeDialogVisible="(val) => (communicationDialogVisible = val)"
      :currentId="currentComId"
    ></CommunicationDialog>
  </div>
</template>
<script>
import CommunicationDialog from "@/views/interpolation/communicationDialog";
import { getAccessList } from "@/service/userCenter";
export default {
  name: "AccessList",
  components: { CommunicationDialog },
  data() {
    return {
      tableData: [],
      currentPage: 1,
      total: 0,
      form: {},
      communicationDialogVisible: false,
      currentComId: "",
    };
  },
  methods: {
    getAccessList(page) {
      this.currentPage = page ? page : 1;
      getAccessList({ current: this.currentPage, ...this.form }).then((res) => {
        if (res.success) {
          this.tableData = res.result.records;
          this.total = res.result.total;
        }
      });
    },
    openCommunicationDialog(item) {
      this.communicationDialogVisible = true;
      this.currentComId = item.id;
    },
  },
  mounted() {
    this.getAccessList();
  },
};
</script>

<style scoped lang="scss">
::v-deep .el-table th {
  background: #f4f4f4;
  font-size: 14px;
  font-family: SourceHanSansCN-Medium, SourceHanSansCN;
  font-weight: 500;
  color: #34495e;
  line-height: 14px;
  padding-left: 12px;
}
::v-deep .el-table td {
  padding: 20px 12px;
}
.companyLogo {
  width: 30px;
  height: 30px;
  vertical-align: top;
}
.companyName {
  line-height: 30px;
}
</style>